<template>
    <div>
        <!-- Header -->
        <!-- <nav
            class="navbar navbar-expand-xl"
        > -->
                <!-- Header -->
        <nav class="navbar navbar-expand-xl bg-gradient-to-r from-teal-600 to-teal-400 text-white">
            <div class="container mx-auto flex items-center justify-between">

                <router-link class="navbar-brand" :aria-label="$t('Home-page')" :to="getRouteLocale('index')">
  <img :src="`${publicPath}assets/svg/logo-header.svg`" alt="" />
                  </router-link>

                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    @click="toggleOffcanvas"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="offcanvas offcanvas-start justify-content-between" id="offcanvasExample" :class="{ show: showMobileNav }">
                    <div class="offcanvas-header">
                        <button
                            type="button"
                            id="btn-close-header"
                            class="btn-close me-3 m-c"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            @click="toggleOffcanvas"
                        ></button>
                    </div>
                    <ul class="navbar-nav">
                        <slot :closeNavList="closeNavList" :clickLink="clickLink"></slot>
                    </ul>
                    <div class="d-flex">
                        <div class="d-flex res-search align-items-center header-end-actions">
                            <template v-if="token">
                                <UserMsg :prefixRoute="prefixRoute" />
                                <UserNotif :prefixRoute="prefixRoute" />
                                <UserNav :prefixRoute="prefixRoute" />
                            </template>
                            <template v-else>
                                 <button @click="router_push('login')" class="btn px-2 py-2 btn-register" >
                                    تسجيل الدخول
                                </button>
                                <button @click="router_push('register')" class="btn px-3 py-2 btn-login">
                                    سجل الان
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import ArrowDownSVG from "@/components/icon-svg/arrow-down.vue";

import UserNav from "@/layouts/common/user-nav.vue";
import UserMsg from "@/layouts/common/user-msg.vue";
import UserNotif from "@/layouts/common/user-notif.vue";
//import SearchInput from "./search.vue";

export default {
    name: "default-header",
    props: {
        prefixRoute: {
            type: String,
            default: "",
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        UserNav,
        UserMsg,
        UserNotif,
    },
    data() {
        return {
            lang: "ar", // Default language
            showMobileNav: false,
        };
    },
    methods: {
        closeNavList() {
            this.showMobileNav = false;
        },
        clickLink(navigate, evnt) {
            this.closeNavList();
            navigate(evnt);
        },
        toggleOffcanvas() {
            this.showMobileNav = !this.showMobileNav;
        },
         switchLanguage(lang) {
            this.$i18n.locale = lang.toLowerCase(); // Update i18n locale
            this.lang = lang;
            localStorage.setItem("i18n_lang", lang); // Persist selected language

            const currentPath = this.$route.path;
            const currentLang = lang === "ar" ? "ar" : "en";

            // Dynamically replace language prefix in the path
            const newPath = currentPath.replace(/^\/(ar|en)/, `/${currentLang}`);

            // Check if navigation is redundant
            if (currentPath !== newPath) {
                this.$router.replace(newPath).catch((err) => {
                    if (err.name !== "NavigationDuplicated") {
                        console.error(err);
                    }
                });
            }

             this.updateLayoutDirection(lang);
        },
        updateLayoutDirection(lang) {
            const direction = lang === "ar" ? "rtl" : "ltr";
            document.documentElement.setAttribute("dir", direction);
        },
        logout() {
            window.store.commit("auth/CLEAR_TOKEN");
            window.store.commit("auth/CLEAR_USER");
            window.location.reload();
        },
    },
    mounted() {
    const savedLang = localStorage.getItem("i18n_lang") || "ar"; // Default to "ar"
    this.lang = savedLang;
    this.$i18n.locale = savedLang;

    // Update route path on reload to include correct language
    const currentPath = this.$route.path;
    if (!currentPath.startsWith(`/${savedLang}`)) {
        this.$router.replace(`/${savedLang}${currentPath}`);
    }

    this.updateLayoutDirection(savedLang);
},
};
</script>

<style scoped>
.navbar-brand {
    margin-right: 0;
}

a.nav-link {
    text-align: start;
    white-space: nowrap;
}
.dropdown-toggle-custom {
    background-color: #1fb9b3 !important; /* Primary color */
   /* color: white !important; /* White text color */
    border: none;
    font-family: "Poppins", sans-serif;
    font-size: 12.96px;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
}

/* Style for dropdown items */
.custom-dropdown .dropdown-item {
    color: #1fb9b3 !important; /* Primary color for item text */
    font-family: "Poppins", sans-serif;
    font-size: 12.96px;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
}

/* Hover effect for dropdown items */
.custom-dropdown .dropdown-item:hover {
    background-color: rgba(
        31,
        185,
        179,
        0.1
    ) !important; /* Light hover effect */
    color: #1fb9b3 !important;
}

@media screen and (max-width: 991px) {
    .header-end-actions {
        margin-top: 2rem;
        gap: 1rem;
        flex-direction: column;
        width: 100%;
        align-items: stretch !important;
    }
    .header-end-actions button {
        padding: 1rem 1.5rem !important;
    }
}
.d-flex {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Adjust space between buttons */
}

.btn {
    font-size: 1rem; /* Adjust font size */
    padding: 0.5rem 1.5rem; /* Add padding for better button size */
    white-space: nowrap; /* Prevent line break */
    text-align: center; /* Ensure text is centered */
}

.btn-custom {
   background-color: white !important;
    color: #1FB9B3 !important;
    border-radius: 0.375rem;
    transition: all 0.3s ease;
}

.btn-custom:hover {
    background-color: white;
    color: #1FB9B3;
    border: 1px solid #1FB9B3;
}

.btn-login {
     font-size: 15px;
     background-color: #1fb9b3;
     color: white;
    white-space: nowrap;
}

.btn-register {
     color: #1fb9b3;
    white-space: nowrap;
}

</style>

