<template>
  <div class="mail-subscribe__section">
    <div class="container h-100">
      <div class="row h-100 align-items-center">
        <div class="col-12 col-lg-6 text-center text-lg-start">
          <div class="section-text text-white">
            <h4 class="heading">اشتركي بالقائمة البريدية</h4>
            <p class="subheading">ليصلك كل العروض والاخبار الجديدة</p>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="section-form">
            <form class="d-flex flex-column flex-sm-row justify-content-center justify-content-lg-end">
              <input class="form-input" type="email" placeholder="أكتبي بريدك الالكتروني" />
              <button class="subscribe-btn btn">أشترك</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NetworkLayoutMailSubscribeSection",
};
</script>

<style lang="scss" scoped>
.mail-subscribe__section {
  height: auto;
  background: linear-gradient(-45deg, #1FB9B3, #FFFFFF);
  margin-top: 50px;
  padding: 30px 0;
  width: 90%;
  margin: 20px auto;
  border-radius: 12px;
  text-align: center;

  .section-text {
    margin-bottom: 20px;
    .heading {
      font-size: 36px;
      font-weight: 600;
      line-height: 50px;
      margin-bottom: 0.5rem;
    }

    .subheading {
      font-size: 18px;
      font-weight: 400;
      color: #F6F8F9;
    }
  }

  .section-form {
    display: flex;
    justify-content: center;

    form {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
    }

    .form-input {
      flex: 1;
      max-width: 385px;
      height: 60px;
      border: 1px solid #E6E6E6;
      border-radius: 12px;
      font-size: 18px;
      padding: 10px 20px;
      &::placeholder {
        color: #BCBCBC;
      }
    }

    .subscribe-btn {
      width: 120px;
      height: 60px;
      background-color: #CF1F3E;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      border-radius: 12px;
      transition: background 0.3s ease-in-out;
      &:hover {
        background-color: #B81C36;
      }
    }
  }

  // Responsive Styles
  @media (max-width: 768px) {
    .section-text .heading {
      font-size: 28px;
      line-height: 40px;
    }

    .section-text .subheading {
      font-size: 16px;
    }

    .section-form {
      form {
        flex-direction: column;
      }

      .form-input {
        width: 100%;
        max-width: 100%;
      }

      .subscribe-btn {
        width: 100%;
      }
    }
  }
}
</style>
